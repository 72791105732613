body {
  margin: 0;
  overflow-y: hidden;
  background-color: #e0e3da;
}

h6 {
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
